.danksagung-box{
    height: 50vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px;
}

.col-text-danksagung{
    text-align: center;
    width: 100%;
    padding: 20px;

}

.col-text-danksagung p{
    font-size: clamp(18px, 2vw, 32px);
    color: #791d40;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    width: 100%;

}

.danksagung-title{
    font-size: clamp(30px, 3vw, 70px);
    color: #791d40;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    width: 100%;
}