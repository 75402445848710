@font-face {
    font-family: 'Daizen Script';
    src: url('../../../assets/Fonts/daizen-script-font/Font/Daizen.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Doctor Who Script';
    src: url('../../../assets/Fonts/Doctor-Who.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Cormorant Upright';
    src: url('../../../assets/Fonts/Cormorant_Upright/CormorantUpright-Light.ttf');
}