
.img-scrollbackground{
    background-image: url("../../../assets/OurPics/sunset/sunset3.jpeg");
    background-size: cover;
    background-position: 59%;
    background-attachment: fixed; /*looks like Pic fix, components are moving*/
    z-index: -100;
    height: 100vh;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-wrap: wrap;

}

.p-title{
    color: #ffffff;
    font-size: clamp(20px, 17vw, 150px);
    font-weight: 6  00;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    text-align: center;
    font-family: "Doctor Who Script";
    word-spacing: 1vw;
    margin: 100px;
}

.countdown-label{
    color: rgb(255, 255, 255);
    font-size: clamp(14px, 10vw, 15px);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    text-align: center;
}

.p-counter {
    font-size: clamp(18px, 10vw, 25px);
    color: rgb(218, 218, 218);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    transition: transform 0.5s ease, opacity 0.5s ease;
    animation: slideIn 1s ease;
    background-color: rgba(37, 0, 0, 0.342);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); 
    border: 1px solid rgb(255, 255, 255);
    border-radius: 20px; 
    padding: 10px;
    display: inline-block;
    width: clamp(100px, 40vw, 350px);

}

.countdown-title-label{
    color: rgb(255, 255, 255);
    font-size: clamp(14px, 10vw, 25px);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: 1000;
    padding: 10px;

}

.p-counter span {
    display: inline-block;
    transition: transform 0.5s ease, opacity 0.5s ease;
    animation: slideIn 1s ease;
  }
  
  @keyframes slideOut {
    100% {
      opacity: 0;
      transform: translateY(30px); /* Geht nach unten */
    }
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-30px); /* Startet oben */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Endposition */
    }
  }
  