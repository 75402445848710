.map {
    margin: 0px;
    padding: 0px;
    border: 2px solid #791d40;
    display: flex;
    justify-content: center;
    height: 100%;
    min-height: 150px;
    width: 80%;
}
@media screen and (max-width: 995px){
    .map{
        width: 100%;
    }
    
}