body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, #ffedf4 0%, #f6eaff 100%);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  margin:0;
  padding: 0;
}
h1{
  font-size: clamp(30px, 10vw, 100px);
  color: rgb(73, 0, 0);
  margin: 10px;
  font-family: "Doctor Who Script";
  font-weight: 1000;
}