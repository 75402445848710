
.frame-img{
    border-radius: 5%;
    cursor:pointer;
    border-radius: 20px;
    overflow: hidden;
    margin: 0;
    padding: 75px;
}
@media screen and (max-width: 995px){
    .frame-img{
        padding: 10px;
    }
    
}

.img-farbpallette{
    margin: 0px;
    padding: 0px;
    width: clamp(300px, 15vw, 500px);
}

.dresscode-section{
    height: clamp(100vh, 100%, 110vh);
    width: 100%;
    align-items: center;
}
.col-1{
    background: linear-gradient(180deg, #5c0000 1%, transparent 1%),
    linear-gradient(90deg, #791d40 0%, #631734 100%, transparent 100%);
    height: 100%;
}
.col-2{
    background: linear-gradient(180deg, #5c0000 1%, transparent 1%),
    linear-gradient(90deg, #791d40 0%, #631734 100%, transparent 100%);
    height: 100%;
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
}
.p-farbpalette{
    font-size: clamp(20px, 10vw, 40px);
    color: rgb(255, 255, 255);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 600;
    padding: 25px;
}
@media screen and (max-width: 995px){
    .p-farbpalette{
        padding: 0;
    }
}

.p-title-dresscode{
    font-size: clamp(30px, 10vw, 80px);
    color: rgb(255, 255, 255);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 600;
}

.farbpalette-bg{
    background: #00000090;
    padding: 5px;
    margin: 0px;
    padding: 10px;
    width: fit-content;
}