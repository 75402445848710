:root{
    --font-size: clamp(14px, 3vw, 18px)
}

.header-style{
    /* z-index: 9999; */
    /* position: sticky; */
    top:0;
    left: 0;
    /* backdrop-filter: blur(10px); */
    background-color: rgb(255, 255, 255);
    border-bottom: 5px solid #4a0000; 
    height: 50px;

    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-right: 10px;
}

.nav-link {
    color: #4a0000;
    /* color: white; */
    text-decoration: none;
    text-transform: uppercase;
    font-size: var(--font-size);
    letter-spacing: 2px;
    font-weight: 500;
}

.nav-link:hover{
    /* text-shadow: 3px 3px black; */
    box-shadow: 0px 3px 0px 0px #4a0000;
}