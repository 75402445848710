.wedding-section
{
    margin: 0;
    padding: 0;
    height: clamp(100vh, 100%, 110vh);
    width: 100%;
    
}

.wedding-logo{
    width: clamp(150px, 10vw, 200px);
    height: auto;
    cursor: pointer;
    margin:0px;
    padding: 0px;
    margin-bottom: 5px
}

p{
    font-size: clamp(16px, 5vw, 20px);
    color: rgb(73, 0, 0);
    /* color: rgb(66, 1, 101); */
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.col-2-imagecontainer{
    background: linear-gradient(90deg, #791d40 0%, #631734 100%);
    display: flex;
    align-items: center;
}


.col-2-verlobung-img{
    margin-left: -50px;
    margin-right: 50px;
    transition: "ease-in-out" 0.5s;

}
@media screen and (max-width: 995px){
    .col-2-verlobung-img{
        margin-left: 0px;
        margin-right: 0px;
        max-height: 500px;
    }
    .col-2-imagecontainer{
        background: linear-gradient(0deg, #791d40 0%, #631734 80%, transparent 80%);
        justify-content: center;
    }

}


.col-1-textcontainer{
    flex-direction: column;
    align-items: center;
    height: 100%;

}
.ionGrid-p-InfosWedding{
    margin: 15px;
    margin-top: 0px;
}

.mapcomponent{
    width: 100%;
    height: clamp(100px, 50vw, 400px);
    margin-top: 40px;
}

.p-place-kesselhaus{
    font-weight: 900;
}

.link-page-kesselhaus{
    text-decoration: none;
    width: fit-content;

    p{
        color: rgb(0, 0, 0);
        font-weight: 400;
        width: fit-content;

    }
}

.col-img-list{
    cursor: pointer;
    overflow-y: scroll;
    flex-wrap: wrap;
    display: flex;
    max-width: fit-content;
    max-height: 400px;
    justify-content: center;
}
.col-img-list img{
    transition: transform 0.5s;
    margin: 5px;
    height: auto;
}
.col-img-list img:hover{
    transform: scale(1.03);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}


