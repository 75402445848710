.section-photo{
    min-height: 800px;
    justify-content: center;
    align-items: center;
    width: 100%;

    background-image: url("../../../assets/OurPics/Bern.jpeg");
    background-size: cover;
    background-position: 0;
    background-attachment: fixed; /*looks like Pic fix, components are moving*/
    z-index: -100;
    height: fit-content;
    border: 2px solid #791d40;


}
.section-photo-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    margin: 0;
    border: 1px solid #791d40;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px #791d40;
    max-width: 70%;
    max-height: 500px;
    background-color: white;
}

.row-photo{
    padding: 0px;
    margin: 0px;
    flex-direction: column;
    align-items: center;
}

.photo-collection{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin: 0px;
    padding: 0px;
    border: 1px solid #791d40;
    min-height: 200px;
    width: 80%;
}
